import {Box, Typography} from "@mui/material";
import AboutMe from "./AboutMe";
import EngineeringNotebook from "./EngineeringNotebook";
import SystemsSimulation from "./SystemsSimulation";


export default () => {
    return <Box sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        gap: "40px",
        alignItems: "center"
    }}>
        <AboutMe/>
        <EngineeringNotebook/>
        <SystemsSimulation/>

        <Typography variant="subtitle2" align={"center"}
                    sx={{
                        position: "fixed",
                        bottom: "0px",
                        width: "100%"
                    }}>
            I &#128151; <a href={"https://vercel.com/"}>Vercel</a>
        </Typography>
    </Box>
}