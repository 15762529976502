import {Avatar, Button, Card, CardActions, CardContent, CardHeader, Typography} from "@mui/material";
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';

export default () => {
    return <Card elevation={1} sx={{
        width: "20%",
        minHeight: "70%"
    }}>
        <CardHeader
            avatar={
                <Avatar
                    alt="EngineeringNotebook"
                    sx={{width: 56, height: 56}}
                >
                    <MenuBookTwoToneIcon sx={{width: 56, height: 56}}/>
                </Avatar>
            }/>
        <CardContent>
            <Typography color="text.secondary" variant={"h5"}>
                {"Engineering Notebook"}
            </Typography>
            <Typography variant="body2">
                {"Fundamentals, but also other cheeky stuff that I try to keep in mind"}
            </Typography>
        </CardContent>
        <CardActions>
            <Button href={"/engineering-notebook"} size="small">See More</Button>
        </CardActions>
    </Card>

}