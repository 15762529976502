import {Avatar, Box, Card, CardActions, CardContent, CardHeader, IconButton, Typography} from "@mui/material";
import PretkiIcon from './ppretki.png'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

function numberOfHoursSinceBirth() {
    const birth = new Date("07/03/1978");
    const now = new Date();
    const diffInMilliseconds = now.getTime() - birth.getTime();
    return Math.round(diffInMilliseconds / (1000 * 3600));
}

const Line = ({label, value}) => {
    return <Box>
        <span>{label}</span>
        <span>{value}</span>
    </Box>
}

export default () => {

    const contactMe = <a href={"mailto:info@przemek-pretki.com"}>contact me</a>;
    const heroes = <>
        <a href={"https://www.kentbeck.com/"}>Kent</a>,&nbsp;
        <a href={"https://martinfowler.com"}>Martin</a>,&nbsp;
        <a href={"https://vaughnvernon.com/"}>Vaughn</a>,&nbsp;
        <a href={"https://www.linkedin.com/in/ericevansddd/"}>Eric</a>,&nbsp;
        <a href={"https://en.wikipedia.org/wiki/Joshua_Bloch"}>Joshua</a>,&nbsp;
        <a href={"https://www.davefarley.net/"}>Dave</a>,&nbsp;
        <a href={"https://www.adam-bien.com/"}>Adam</a>,&nbsp;
        <a href={"https://en.wikipedia.org/wiki/Robert_C._Martin"}>Robert</a>,&nbsp;
        <a href={"https://www.linkedin.com/in/avrahampoupko"}>Avraham</a>
    </>;

    return <Card elevation={1} sx={{
        width: "20%",
        minHeight: "70%"
    }}>
        <CardHeader
            avatar={
                <Avatar
                    alt="Przemek Pretki"
                    src={PretkiIcon}
                    sx={{width: 56, height: 56}}
                />
            }/>
        <CardContent>
            <Typography color="text.secondary" variant={"h5"}>
                {"Przemek Prętki"}
            </Typography>
            <Line label={"Age:"} value={"46 years / " + numberOfHoursSinceBirth() + " hours"}></Line>
            <Line label={"Height:"} value={"1.88 m / 6'1 feet"}></Line>
            <Line label={"Weight:"} value={"84 Kg / 185 lb"}></Line>
            <Line label={"Mission:"} value={"building high-quality software"}></Line>
            <Line label={"Enemy:"} value={"complexity"}></Line>
            <Line label={"Friend:"} value={"engineering principles"}></Line>
            <Line label={"Fan of:"} value={"domain driven design, structured design"}></Line>
            <Line label={"Heroes:"} value={heroes}></Line>
            <Line label={"Avail.:"} value={contactMe}></Line>
        </CardContent>
        <CardActions>
            <IconButton href={"https://www.linkedin.com/in/ppretki/"}>
                <LinkedInIcon/>
            </IconButton>
            <IconButton href={"https://www.linkedin.com/in/ppretki/"}>
                <TwitterIcon href={"https://twitter.com/przemekpretki"}/>
            </IconButton>
        </CardActions>
    </Card>
}