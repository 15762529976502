import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import MainPage from "./main-page/MainPage";
import AboutMe from "./main-page/AboutMe";
import SystemsSimulation from "./main-page/SystemsSimulation";
import {createTheme, responsiveFontSizes, ThemeProvider} from "@mui/material";
import TableOfContents from "./engineering-notebook/TableOfContents";

let theme = createTheme();
theme = responsiveFontSizes(theme);


export default () => {
    return <ThemeProvider theme={theme}>
        <Router>
            <Routes>
                <Route path="/" element={<MainPage/>}/>
                <Route path="/about" element={<AboutMe/>}/>
                <Route path="/engineering-notebook" element={<TableOfContents/>}/>
                <Route path="/system-simulation" element={<SystemsSimulation/>}/>
            </Routes>
        </Router>
    </ThemeProvider>
}