import {Avatar, Button, Card, CardActions, CardContent, CardHeader, Typography} from "@mui/material";
import PlayCircleFilledTwoToneIcon from '@mui/icons-material/PlayCircleFilledTwoTone';


export default () => {
    return <Card elevation={1} sx={{
        width: "20%",
        borderRadius: "8px",
        minHeight: "70%"
    }}>
        <CardHeader
            avatar={
                <Avatar
                    alt="System Simulation"
                    sx={{width: 56, height: 56}}
                >
                    <PlayCircleFilledTwoToneIcon sx={{width: 56, height: 56}}/>
                </Avatar>
            }/>
        <CardContent>
            <Typography color="text.secondary" variant={"h5"}>
                {"Smart System Design"}
            </Typography>
            <Typography variant="body2">
                {"Simulator which allows designers to test concepts such as scalability, reliability and costs at design stage without building PoC."}
            </Typography>
        </CardContent>
        <CardActions>
            <Button href={"https://smart-system-design.vercel.app"} size="small">Open the application</Button>
        </CardActions>
    </Card>
}